import { createRouteMiddleware } from "~/composables/useStateRouteBinding";
import {
  expectSimpleNamedLocation,
  getBibleLocationFromState,
} from "~/routing/locations";
import { getDesiredBibleStateFromLocation } from "~/routing/bibleAndRoute";
import { useBibleStore } from "~/stores/useBibleStore";
import { getLocaleFromRoute, localesByCode } from "~/i18n/locales";
import { never } from "~/never";

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useBibleStore();
  const runtimeConfig = useRuntimeConfig();
  const locale =
    getLocaleFromRoute(to) ?? never("Failed to get locale from route.");
  const localeConfig =
    localesByCode.get(locale) ?? never("Failed to get locale config.");
  const nuxt = useNuxtApp();

  const ware = createRouteMiddleware(
    () =>
      store.getRouteState(
        runtimeConfig.public.bible.defaultVids,
        localeConfig.defaultTid,
      ),
    (value) => store.setRouteState(value),
    (state) =>
      expectSimpleNamedLocation(
        nuxt.$localeRoute(getBibleLocationFromState(state)),
      ),
    getDesiredBibleStateFromLocation,
  );

  return ware(to, from);
});
